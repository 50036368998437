$blue: #3F784C; //main color
$color-1: #3F784C; //main color
$bg-color-1: #C17817; //discount badge
$bg-color-2: #3F784C; //category background
$bg-color-3: #F7FFF7; //homepage background
$bg-color-4: #9C9E94; //article h2


@media (min-width: 1024px) {
    .main_home {
        background: url('https://static.listeo.ro/homepage.jpg') no-repeat top center;
        background-size: 100% auto;
        padding-top: 15%;
        background-color: white;
    }

    .main_title_home {
        margin-top: 30px;
    }

    .container_home {
        background: #fff;
    }
}

.articleH2 {
    background: -webkit-linear-gradient(#000 0, #6b6e65 50%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #1a1a1a;
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: bold;
}

.articleH1 {
    background: -webkit-linear-gradient(#dd9dff 0, $blue 50%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #1a1a1a;
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: bold;
}
